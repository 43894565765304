import type { CampaignBadgeData } from '~/server/transformers/shop/campaignTransformer';

export function useOrderedCampaigns<
  T extends CampaignBadgeData = CampaignBadgeData,
>(campaigns: T[] = [], productId?: string) {
  const orderedCampaigns = computed(() => {
    return campaigns.sort((a, b) => {
      return a.priority - b.priority;
    });
  });

  const displayCampaign = computed(() => {
    const filteredCampaigns = orderedCampaigns.value.filter((campaign) => {
      //filter out campaigns where the product is a free product
      return !campaign.products.some(
        (item) => productId === item.productId && item.isFree,
      );
    });

    return filteredCampaigns[0];
  });

  return {
    orderedCampaigns,
    displayCampaign,
  };
}
